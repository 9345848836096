@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap");

html {
  font-family: "Poppins", sans-serif;
}

@media (max-width: 800px) {
  html {
    font-size: 2vw;
  }
}

@media (max-width: 670px) {
  html {
    font-size: 2.2vw;
  }
}

@media (max-width: 580px) {
  html {
    font-size: 2.3vw;
  }
}

@media (max-width: 540px) {
  html {
    font-size: 2.4vw;
  }
}

@media (max-width: 460px) {
  html {
    font-size: 2.4vw;
  }
}

@media (max-width: 400px) {
  html {
    font-size: 2.5vw;
  }
}
